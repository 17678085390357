<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">主要业绩</div>
      <div class = "block-line"></div>
      <div class="list-contain">
        <span v-for="(item,key) in listData" :key="key" :class="changeTag === key ? 'active' : ''" @click="changeTag = key">{{ key }}</span>
      </div>
      <div class = "list-container">
        <ul class = "list-list">
          <li class="list-item" v-for="item in listData[changeTag]" :key="item.id">
            <el-image 
              style="width: 100%; height: auto"
              :src="item.img" 
              :preview-src-list="[item.img]">
            </el-image>
            <p>《{{ item.title }}》</p>
          </li>
        </ul>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
      changeTag: '总体战略规划',
      listData: {
        '总体战略规划': [{
          id: 0,
          title: '大连2049城市愿景规划',
          time: '2023-11-23',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_07.jpg')
        }, {
          id: 1,
          title: '大连市国土空间总体规划(2021-2035年)',
          time: '2023-10-26',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_08.jpg')
        }],
        '详细规划': [{
          id: 0,
          title: '大连市国土空间详细规划编制管理实施机制专题研究',
          time: '2023-10-31',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_10.jpg')
        }, {
          id: 1,
          title: '甘井子区椒金山1控制单元详细规划',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_10.jpg')
        }, {
          id: 2,
          title: '大连市东港商务区（中山片区1单元东部地区）控制性详细规划',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_11.jpg')
        }, {
          id: 3,
          title: '大连市新体育中心修建性详细规划',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_11.jpg')
        }],
        '城市更新规划': [{
          id: 0,
          title: '大连市青泥洼桥天津街总体改造提升规划',
          time: '2023-10-31',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_13.jpg')
        }, {
          id: 1,
          title: '大连201路有轨电车沿线城市更新规划',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_13.jpg')
        }, {
          id: 2,
          title: '机车厂搬迁改造项目策划报告',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_14.jpg')
        }, {
          id: 3,
          title: '大连市城市更新专项规划',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_14.jpg')
        }, {
          id: 4,
          title: '体育新城周边地区控制性详细规划及城市设计',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_07.jpg')
        }],
        '城市设计与历史保护': [{
          id: 0,
          title: '大连市总体城市设计',
          time: '2023-10-31',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_16.jpg')
        }, {
          id: 1,
          title: '大连市历史文化名城保护规划',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_16.jpg')
        }, {
          id: 2,
          title: '大连市钻石湾地区城市设计',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_17.jpg')
        }, {
          id: 3,
          title: '大连市中山广场历史文化街区保护规划',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_17.jpg')
        }],
        '专项规划': [{
          id: 0,
          title: '大连市世界自然遗产提名地保护与利用规划',
          time: '2023-10-31',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_19.jpg')
        }, {
          id: 1,
          title: '大连市城市绿地系统规划（2021-2035年）',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_19.jpg')
        }, {
          id: 2,
          title: '大连市综合交通体系规划（2021-2035年）',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_20.jpg')
        }, {
          id: 3,
          title: '大连市海岸线资源保护与开发利用规划（2015-2030）',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_20.jpg')
        }],
        '信息技术与应用': [{
          id: 0,
          title: '大连市多规合一“一张蓝图”项目',
          time: '2023-10-31',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_22.jpg')
        }, {
          id: 1,
          title: '大连市国土空间规划年度城市体检评估及实时体检评估国家试点',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_23.jpg')
        }, {
          id: 2,
          title: '大连市国土空间规划设计有限公司高新技术产品研发',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_23.jpg')
        }],
        '国土与测绘': [{
          id: 0,
          title: '大连市域0.6米卫星影像采购及DOM制作项目',
          time: '2023-10-31',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_25.jpg')
        }, {
          id: 1,
          title: '大连市2022年度国土变更调查',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_26.jpg')
        }, {
          id: 2,
          title: '大连市全民所有自然资源资产清查项目',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_26.jpg')
        }],
        '市政工程设计': [{
          id: 0,
          title: '金州北山路南段延伸工程',
          time: '2023-10-31',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_28.jpg')
        }, {
          id: 1,
          title: '中华路改建工程',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_28.jpg')
        }, {
          id: 2,
          title: '红凌路交通综合改造工程',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_29.jpg')
        }, {
          id: 3,
          title: '华东路交松江路人行天桥工程',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_29.jpg')
        }, {
          id: 4,
          title: '大连三鼎春天B区项目',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_30.jpg')
        }, {
          id: 5,
          title: '大连东北财经大学东尖山改造项目',
          time: '2023-08-17',
          img: require('../../assets/xcc/2_2_公司宣传册（方形）v9_30.jpg')
        }]
      }
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
  .list-contain {
    margin: 30px 0;
    text-align: center;
    span {
      position: relative;
      display: inline-block;
      color: #909399;
      padding: 0 10px;
      cursor: pointer;
    }
    span:after {
      content: "";
      position: absolute;
      width: 1px;
      height: 12px;
      top: 5px;
      right: 0;
      background-color: #909399;
    }
    span:last-child:after {
      display: none;
    }
    span.active {
      color: #0b1660;
      font-weight: bold;
    }
  }

  .list-container {
    min-height: 400px;
    margin: 0 auto;

    .list-list {
      margin-top: 15px;
      .list-item {
        cursor: pointer;
        width: 50%;
        box-sizing: border-box;
        padding: 10px;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        img {
          width: 100%;
          height: 250px;
          object-fit: cover;
        }
        p {
          margin: 10px 0 20px;
        }
      }
      @media (max-width: 800px) {
        .list-item {
          width: 100%;
        }
      }
      .list-item:hover {
        color: #0b1660;
        opacity: 0.8;
      }
      .list-item::before {
        content: "";
        position: absolute;
        top: 17px;
        left: -10px;
        background-color: #0b1660;
        width: 5px;
        height: 5px;
        border-radius: 5px;
      }
    }
  }
}
</style>